<template>
  <div class="search">
    <div class="search-all">
      <div class="search-box flex-between">
        <div class="left" @click="$router.go(-1)"></div>
        <div class="center ellipsis">提现</div>
        <router-link tag="div" to="/myBill" class="right">我的账单</router-link>
      </div>
      <div class="section">
        <div class="wallet flex-column">
          <div class="title">钱包余额（元）</div>
          <div class="num"><span></span>{{banlance}}</div>
        </div>
        <div class="sec-bottom">
          <div class="bank flex-around">
            <div class="get-bank" :class="{active:bank}" @click="useBank">提现到银行卡</div>
            <div class="get-bank" :class="{active:!bank}" @click="useUsdt">提现到USDT</div>
          </div>
          <div class="add flex-between" :class="{usdtadd:!bank}" @click="selectBankCard">
            <div class="add-left flex" v-show="bank">
              <div class="bag"></div>
              <div class="add-w">
                <div v-if="bankCardInfo.cardNum">{{`${bankCardInfo.bankType}（${bankCardInfo.cardNum.slice(-4)}）`}}</div>
                <div v-else class="add-title">请添加银行卡账号</div>
                <div class="add-sec">24小时内</div>
              </div>
            </div>
            <div class="usdt flex" v-show="!bank">
              <div class="addres">地址</div>
              <input class="usdt-i" placeholder="请输入钱包地址（trc20)" type="text" v-model="trcAddress">
            </div>
            <div class="add-right" v-show="bank"></div>
          </div>
          <div class="money-num flex-between">
            <div class="yuan">¥</div>
            <input class="input" placeholder="请输入提现金额" type="number" v-model="withdrawAmount" @input="changeAmount">
            <div class="all" @click="setMaxAmount">全部</div>
          </div>
          <div class="m-sec flex-between">
            <div class="m-left">1元=1金币</div>
            <div class="m-right">
              <p>手续费：2% 到账：{{realAmount}}元</p>
              <p class="one">单笔限额¥{{minMoney}}-{{maxMoney}}</p>
            </div>
          </div>
          <div class="btn" @click="withdraw">确认</div>
          <div class="tx-title">提现说明</div>
          <div class="tx-sec">
            1、每次提现金额必须满足{{minMoney}}-{{maxMoney}}的额度才能提现<br />
            2、提交提现申请后24小时内到账<br />
            3、请确保银行卡信息正确，若错误会导致提现失败<br />
            4、USDT提现金额为人民币，非USDT数量<br />
            5、申请提现后请随时关注收款账户进款通知，长时间未到账，请及时联系客服
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  import {
    getBanlance
  } from "@/api/app.js";
  import {
    queryBankCard,
    queryWithdrawMethod,
    withdraw
  } from '@/api/user.js'
  export default {
    data() {
      return {
        bank: true,
        banlance: 0, //钱包余额
        maxMoney: 0, // 最大提现金额
        minMoney: 0, // 最小提现金额
        handlingFee: 2, // 手续费
        formalitiesAmount: 0, //手续费金额
        realAmount: 0, // 实际到账金额
        withdrawAmount: "", // 提现金额
        bankCardInfo: {}, // 银行卡信息
        trcAddress: "", // trc地址
      }
    },
    created() {
      this.getBanlance();
      this.queryBankCard();
      this.queryWithdrawMethod();
    },
    methods: {
      async getBanlance() {
        this.$store.dispatch("app/GET_LOADING", true);
        let ret = await this.$Api(getBanlance, undefined);
        if (ret.code == 200) {
          this.banlance = ret.data.wlBalance;
        }
        this.$store.dispatch("app/GET_LOADING", false);
      },
      useBank() {
        this.bank = true;
      },
      useUsdt() {
        this.bank = false;
      },
      async queryBankCard() {
        let ret = await this.$Api(queryBankCard);
        if (ret && ret.code == 200) {
          this.bankCardInfo = ret.data.bankInfo;
        }
        // console.log(ret)
      },
      //设置最大金额
      setMaxAmount() {
        if (this.banlance <= this.maxMoney) {
          this.withdrawAmount = this.banlance;
        } else if (this.banlance >= this.maxMoney) {
          this.withdrawAmount = this.maxMoney;
        }
        this.changeAmount();
      },
      // 查询提现金额区间
      async queryWithdrawMethod() {
        let ret = await this.$Api(queryWithdrawMethod);
        if (ret && ret.code == 200) {
          let arr = ret.data.info ? ret.data.info : [];
          arr.forEach(i => {
            if (i.wtdrMode == 3) {
              this.maxMoney = i.maxAmount / 100;
              this.minMoney = i.qpMinAmount / 100;
              // this.bankHandlingFee = i.rate;
            }
          });
        }
      },
      // 计算金额
      changeAmount() {
        // let money = this.banlance;
        // if (this.withdrawAmount > money) {
        //   this.$toast('余额不足');
        //   return;
        // }
        this.formalitiesAmount = (this.withdrawAmount * this.handlingFee / 100).toFixed(2);
        this.realAmount = (this.withdrawAmount - this.formalitiesAmount).toFixed(2);
      },
      selectBankCard() {
        if(!this.bank) return;
        if (this.bankCardInfo.cardNum) {
          this.$router.push('bankCard');
        } else {
          this.$router.push('bindBankCard');
        }
      },
      // 提现
      async withdraw() {
        if (!this.bankCardInfo.cardNum && this.bank) {
          this.$toast('请添加银行卡');
          return;
        }
        if (!this.trcAddress && !this.bank) {
          this.$toast('请输入钱包地址');
          return;
        }

        let req = {
          cardNo: this.bank ? this.bankCardInfo.cardNum : this.trcAddress,
          bankCode: this.bank ? this.bankCardInfo.bankCode : undefined,
          coin: Number(this.withdrawAmount),
          realName: this.bankCardInfo.name,
          productType: 1, //0 站群 1 棋牌
          withdrawType: this.bank ? 3 : 4,
        }
        if (!this.withdrawAmount) {
          this.$toast('请输入提现金额');
          return;
        }
        if (this.withdrawAmount > this.banlance) {
          this.$toast('余额不足');
          return;
        }
        if (this.withdrawAmount > this.maxMoney) {
          this.$toast(`单笔提现最大${this.maxMoney}`);
          return;
        }
        if (this.withdrawAmount < this.minMoney) {
          this.$toast(`单笔提现小${this.minMoney}`);
          return;
        }
        this.$store.dispatch("app/GET_LOADING", true);
        let ret = await this.$Api(withdraw, req);
        if (ret && ret.code === 200) {
          this.$toast('已提交申请，请耐心等待')
          this.getBanlance();
          this.withdrawAmount = "";
        } else {
          this.$toast(ret.tip || '提现失败，请稍后再试');
        }
        this.$store.dispatch("app/GET_LOADING", false);
      }
    }
  }
</script>
<style lang="scss" scoped>
  .search-all {
    height: 100%;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
  }

  .search-box {
    padding: 0 16px;
    height: 52px;
    align-items: center;
    background: $vermillion;
  }

  .left {
    width: 22px;
    margin-right: 34px;
    height: 22px;
    background: url('../../assets/png/back_icon.png') center center no-repeat;
    background-size: 100%;
  }

  .center {
    font-size: 20px;
    font-weight: bold;
    color: #ffffff;
  }

  .right {
    width: 56px;
    color: #ffffff;
    font-size: 14px;
  }

  .section {
    height: calc(100% - 52px);
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
    padding: 0 0 30px;
    box-sizing: border-box;
  }

  .wallet {
    justify-content: space-around;
    width: 100%;
    height: 124px;
    padding: 12px 26px;
    box-sizing: border-box;
    border-bottom: 7px solid rgba(242, 19, 19, 0.33);
  }

  .title {
    font-size: 14px;
  }

  .num>span {
    display: inline-block;
    width: 36px;
    height: 36px;
    background: url('../../assets/png/money.png') center center no-repeat;
    background-size: 100%;
    margin-right: 3px;
  }

  .num {
    font-size: 50px;
  }

  .sec-bottom {
    padding: 0 16px;
  }

  .bank {
    margin-top: 20px;
    padding: 7px 0;
    border-bottom: 2px solid rgba(182, 181, 182, 0.33);
  }

  .get-bank {
    font-size: 20px;
  }

  .active {
    color: $vermillion;
    position: relative;
  }

  .active::after {
    position: absolute;
    content: '';
    display: inline-block;
    width: 103px;
    height: 3px;
    border-radius: 2px;
    background: $vermillion;
    left: 50%;
    margin-left: -51.5px;
    bottom: -7px;
  }

  .add {
    margin-top: 40px;
    align-items: center;
  }

  .add-left {
    align-items: center;
  }

  .bag {
    width: 50px;
    height: 50px;
    background: url('../../assets/png/withdraw.png') center center no-repeat;
    background-size: 100%;
  }

  .add-title {
    font-size: 20px;
  }

  .add-sec {
    color: #8b8b8b;
    margin-top: 6px;
  }

  .add-right {
    width: 10px;
    height: 14px;
    background: url('../../assets/png/go_black.png') center center no-repeat;
    background-size: 100%;
  }

  .money-num {
    margin-top: 16px;
    align-items: center;
    border-bottom: 2px solid rgba(182, 181, 182, 0.33);
    padding-bottom: 4px;
  }

  .yuan {
    font-size: 40px;
    margin-right: 12px;
    margin-top: -6px;
  }

  .input {
    flex: 1;
    font-size: 16px;
  }

  .all {
    font-size: 16px;
    color: $vermillion;
  }

  .m-sec {
    margin-top: 10px;
    color: #8b8b8b;
  }

  .one {
    margin-top: 6px;
  }

  .m-right {
    text-align: right;
  }

  .btn {
    height: 40px;
    line-height: 40px;
    border-radius: 20px;
    background: $vermillion;
    text-align: center;
    margin-top: 60px;
    font-size: 20px;
    color: #ffffff;
  }

  .tx-title {
    margin-top: 20px;
    font-size: 20px;
  }

  .tx-sec {
    margin-top: 8px;
    font-size: 13px;
    color: #515151;
    line-height: 22px;
  }

  .usdt {
    flex: 1;
    align-items: center;
  }

  .usdt-i {
    flex: 1;
    font-size: 16px;
  }

  .addres {
    font-size: 18px;
    margin-right: 14px;
  }

  .usdtadd {
    padding-bottom: 14px;
    border-bottom: 2px solid rgba(182, 181, 182, 0.33);
  }
</style>